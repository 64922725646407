<template>
  <div class="infringement_container">
    <el-container>
      <el-main>
        <!-- 头部 -->
        <PageHeader></PageHeader>
        <!-- 中间内容填写部分 -->
        <div class="form_box">
          <div class="details">
            <div class="title_top">
              <div class="title">侵权举报</div>
              <div class="icon" @click="closeToIndex"><i class="el-icon-close"></i></div>
            </div>
            <!-- 表单 -->
            <el-form :model="reportFrom" ref="reportFrom" :rules="rules">
              <div class="cantact_box">
                <div class="title">联系人资料</div>
                <div class="form_item_line">
                  <!-- 姓名 -->
                  <el-form-item prop="phone" label="联系人手机号:" label-width="120px">
                    <el-input placeholder="请输入手机号码" v-model="reportFrom.phone" style="max-width:190px">
                    </el-input>
                  </el-form-item>
                  <!-- 称呼 -->
                  <el-form-item prop="sex" label="称呼:" label-width="120px">
                    <el-radio-group v-model="reportFrom.sex">
                      <el-radio :label="1">先生</el-radio>
                      <el-radio :label="2">女士</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <div class="content_box">
                <div class="title">举报内容</div>
                <div class="form_item_line">
                  <el-form-item prop="type" label="涉及模块:" label-width="120px">
                    <el-radio-group v-model="reportFrom.type">
                      <el-radio :label="1">LD前台</el-radio>
                      <el-radio :label="2">LD学员</el-radio>
                      <el-radio :label="3">LD教员</el-radio>
                      <el-radio :label="4">LDBoss</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <!-- 相关内容 -->
              <div class="form_item_line">
                <el-form-item prop="pic" label="图片:" label-width="120px">
                  <el-upload style="width: 430px;display: flex;flex-direction: column; align-items: flex-start;"
                    class="upload_btn" action="https://api.ldwh.cc/api/admin/upload" accept=".png,.jpeg,.jpg,.bmp"
                    :limit="8" :file-list="reportFrom.pictureList" :on-exceed="uploadLimitHandle"
                    :on-success="upLoadSuccess" :on-progress="uploadingProgress" :before-upload="beforeUpload">
                    <el-button type="primary">上传图片</el-button>
                    <div slot="file" slot-scope="{ file }">
                      <li class="el-upload-list__item is-success">
                        <a @click="() => openUrl(file)" class="el-upload-list__item-name">
                          <i class="el-icon-document"></i>{{ file.name }}
                        </a>
                        <label class="el-upload-list__item-status-label">
                          <i class="el-icon-upload-success el-icon-circle-check"></i>
                        </label>
                        <i @click.stop="handleRemove(file)" class="el-icon-close"></i>
                      </li>
                    </div>
                  </el-upload>
                </el-form-item>
              </div>
              <div class="form_item_line">
                <!-- 内容补充 -->
                <el-form-item prop="content" label="内容补充:" label-width="120px">
                  <el-input placeholder="请输入相关内容" type="textarea" v-model="reportFrom.content">
                  </el-input>
                </el-form-item>
              </div>
              <div>
                <el-button type="primary">提交</el-button>
              </div>
              <el-dialog :visible.sync="dialogVisible" append-to-body @close="clearUrl">
                <img width="100%" :src="photoUrl" alt="">
              </el-dialog>
            </el-form>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <BottomFooter></BottomFooter>
      </el-footer>
    </el-container>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  import PageHeader from '../components/PageHeader'

  export default {
    name: 'WorkspaceJsonLicense',
    components: {
      BottomFooter,
      PageHeader
    },

    data() {
      return {
        license_pic: require('../assets/license.jpg'),
        code_pic: require('../assets/code.png'),
        reportFrom: {
          phone: '',
          sex: '',
          type: '',
          pictureList: []

        },
        rules: {
          phone: [{
            required: true,
            validator: this.checkPhone,
            trigger: 'blur'
          }],
          sex: [{
            required: true,
            message: '请选择性别',
            trigger: 'change'
          }],
          type: [{
            required: true,
            message: '请选择涉及模块',
            trigger: 'change'
          }],
        },
        pictureList: [],
        dialogVisible: false, //查看logo大图弹框
        photoUrl: '', //大图url
        uploadLoading: undefined,

      };
    },

    mounted() {

    },

    methods: {
      /**
       * 手机号码验证规则
       */
      checkPhone(rule, value, callback) {
        if (!value) {
          callback(new Error('手机号码不能为空'))
        } else {
          const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          if (!phoneReg.test(value)) {
            callback(new Error('请输入正确的手机号'))
          } else {
            callback()
          }
        }
      },
      closeToIndex() {
        this.$router.push('/index')
      },
      /**
       * 文件上传时
       */
      uploadingProgress(event, file, fileList) {
        this.uploadLoading = this.$loading({
          lock: true,
          text: '上传中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      },
      // 头像上传成功
      handleAvatarSuccess(res, file) {
        console.log(res);
        console.log(file);
        setTimeout(() => {
          this.addNewFrom.avatar = res.data.url
          this.uploadLoading.close()
        }, 1000)

      },
      // 上传前做限制
      beforeUpload(file) {
        if (file.name.length > 50) {
          this.$message.error('文件名称过长')
          return false
        }
        const isJPG = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file
          .type === 'image/bmp'
        const isLt5M = file.size / 1024 / 1024 < 5
        if (!isJPG) {
          this.$message.error('扫描件仅支持JPG、PNG、BMP、JPEG格式！')
          return isJPG
        }
        if (!isLt5M) {
          this.$message.error('上传图片大小不能超过5MB！')
          return isLt5M
        }
        return isLt5M && isJPG
      },
      /**
       * 超出上传数量限制
       * @constructor
       */
      uploadLimitHandle(files, fileList) {
        this.$message.error('最多可上传8个文件')
      },
      /**
       * 上传附件成功
       * @param res
       * @param file
       */
      upLoadSuccess(res, file) {
        setTimeout(() => {
          this.pictureList.push(file.response.data)
          this.uploadLoading.close()
          this.$refs.reportFrom.validateField('pictureList')
          console.log(this.pictureList);

        }, 1000)
      },

      /**
       * 查看已上传的文件
       * */
      openUrl(file) {
        console.log(file);

        let type = this.extname(file.original)
        if (type == 'jpg' || type == 'png' || type == 'bmp' || type == 'jpeg') {
          this.dialogVisible = true;
          this.photoUrl = file.fileUrl
        } else {
          window.open(file.fileUrl, '_blank')
        }
      },
      /**
       * 获取文件后缀函数serve
       * @param filename
       * @returns {string|boolean}
       */
      extname(filename) {
        if (!filename || typeof filename != 'string') {
          return false
        }
        let a = filename.split('').reverse().join('')
        let b = a.substring(0, a.search(/\./)).split('').reverse().join('')
        return b
      },
      /**
       * 大图弹框关闭时 清空photoUrl
       * */
      clearUrl() {
        this.photoUrl = ''
      },
      /**
       * 删除文件
       * */
      handleRemove(file) {
        //这里根据state来进行是否删除文件库的文件  state=2时是不通过编辑（需要调接口进行删除） state=undefined时只删除attachmentList就可以了
        this.$confirm(`确定移除 ${ file.name }?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let index = this.reportFrom.pictureList.findIndex((item) => {
            return item.name == file.name
          })
          this.reportFrom.pictureList.splice(index, 1)
          this.$nextTick(() => {
            this.$forceUpdate()
          })
        }).catch(() => {})
      },

    },
  };

</script>

<style lang="scss" scoped>
  .infringement_container {
    background: url(".././assets/bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
    width: 100%;
    min-width: 960px;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;

    .el-container {
      height: 100%;

      .el-main {
        height: calc(100vh -100px);

        .form_box {
          width: 100%;
          height: 500px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;

          .details {
            width: 700px;
            border-radius: 10px;
            background-color: #fff;
            box-sizing: border-box;
            padding: 0 60px 20px 50px;

            .title_top {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              margin-bottom: 20px;

              .title {
                font-size: 20px;
                font-weight: bold;
                margin-top: 12px;
              }

              .icon {
                position: absolute;
                top: 10px;
                right: -45px;
                font-size: 25px;
                cursor: pointer;
              }
            }

            .cantact_box,
            .content_box {
              width: 100%;
              text-align: left;

              .title {
                font-weight: bold;
                margin-bottom: 10px;
              }

              .form_item_line {
                width: 100%;
                display: flex;

                .upload_btn {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                }
              }
            }
          }


        }
      }

      .el-footer {
        height: 100px !important;
        background-color: #fff;

        .index_footer {
          width: 90%;
          height: 100%;
          margin: 0px auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left_box {
            width: 350px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .left_item {
              width: 90px;
              cursor: pointer;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }

</style>
